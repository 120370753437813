import * as React from "react"
import PropTypes from "prop-types"

import BlockContainer from "components/blockContainer"
import BlueBanner from "components/blueBanner"
import FooterBlock from "components/footer"
import HowItWorksBlock from "components/HowItWorksBlock"
import Layout from "components/layout"
import MarkdownContent from "components/markdownContent"
import NewsletterCallToActionBloc from "components/newsletterCallToActionBloc"
import PressBlock from "components/pressBlock"
import ProductBlock from "components/productBlock"
import ConceptBlock from "components/conceptBlock"
import QuickBookingForm from "components/quickBookingForm"
import RepairmenBlock from "components/repairmenBlock"
import SEO from "components/seo"
import USPBackgroundImage from "components/USPBackgroundImage"
import USPBlock from "components/USPBlock"
import ReviewsBlock from "components/ReviewsBlock"
import LinksBlock from "components/LinksBlock"
import Navigation from "components/navigation"
import RepairSearch from "components/repairSearch"
import styles from "./template.module.scss"
import PricingBlock from "components/PricingBlock"
import MonoOffer from "components/monoOffer"
import NewHeaderBlock from "components/newHeaderBlock"
import OptInBlock from "components/optInBlock"
import SliderBlock from "components/sliderBlock"
import ReinsuranceBlock from "components/reinsuranceBlock"
import WasteCountBlock from "components/wasteCountBlock"
import MachinaSliderBlock from "components/machinaSlider"
import VideoSliderBlock from "components/videoSlider"
import MurfyLawBlock from "components/murfyLawBlock"

export const LandingPageTemplate = ({
  seoTitle,
  seoDescription,
  headerBannerText,
  displayHeaderBanner,
  blocks,
  navigation,
  footerData,
  products,
  isPreview,
}) => {
  return (
    <Layout isPreview={isPreview}>
      {!isPreview && <SEO title={seoTitle} description={seoDescription} />}
      {displayHeaderBanner && headerBannerText ? (
        <BlueBanner content={headerBannerText} />
      ) : null}
      <div style={{ position: "relative" }}>
        {!isPreview && (
          <Navigation
            navigation={navigation}
            displayHeaderBanner={displayHeaderBanner && headerBannerText}
          />
        )}
        {blocks &&
          blocks.map(
            (block, index) =>
              (block.type === "quickBookingArticleForm" &&
                block.isBlockDisplayable && (
                  <BlockContainer key={index}>
                    <QuickBookingForm
                      {...block}
                      products={products}
                      pageType="article"
                    />
                  </BlockContainer>
                )) ||
              (block.type === "quickBookingForm" &&
                block.isBlockDisplayable && (
                  <BlockContainer key={index}>
                    <RepairSearch
                      {...block}
                      products={products}
                      color="#F7BDBD"
                    />
                  </BlockContainer>
                )) ||
              (block.type === "murfyLaw" && block.isBlockDisplayable && (
                <BlockContainer key={index} noMargin>
                  <MurfyLawBlock {...block} />
                </BlockContainer>
              )) ||
              (block.type === "richText" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <MarkdownContent
                    {...block}
                    pageType="landingPage"
                    className={
                      index === 0
                        ? styles.templateContainerHomepageFirstBlock
                        : styles.templateContainerHomepage
                    }
                  />
                </BlockContainer>
              )) ||
              (block.type === "newsletterCallToActionBloc" &&
                block.isBlockDisplayable && (
                  <BlockContainer key={index}>
                    <NewsletterCallToActionBloc {...block} />
                  </BlockContainer>
                )) ||
              (block.type === "USPBlock" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <USPBlock {...block} isPreview={isPreview} />
                </BlockContainer>
              )) ||
              (block.type === "sliderHomeMarche" &&
                block.isBlockDisplayable && (
                  <BlockContainer key={index}>
                    <SliderBlock {...block} isPreview={isPreview} />
                  </BlockContainer>
                )) ||
              (block.type === "sliderHome" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <MachinaSliderBlock {...block} isPreview={isPreview} />
                </BlockContainer>
              )) ||
              (block.type === "videoSlider" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <VideoSliderBlock {...block} isPreview={isPreview} />
                </BlockContainer>
              )) ||
              (block.type === "wasteCount" && block.isBlockDisplayable && (
                <BlockContainer key={index} noMargin>
                  <WasteCountBlock {...block} isPreview={isPreview} />
                </BlockContainer>
              )) ||
              (block.type === "pressBlock" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <PressBlock {...block} isPreview={isPreview} />
                </BlockContainer>
              )) ||
              (block.type === "productBlock" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <ProductBlock {...block} isPreview={isPreview} />
                </BlockContainer>
              )) ||
              (block.type === "reinsurance" && block.isBlockDisplayable && (
                <BlockContainer key={index} overflowTop>
                  <ReinsuranceBlock {...block} isPreview={isPreview} />
                </BlockContainer>
              )) ||
              (block.type === "USPBackgroundImage" &&
                block.isBlockDisplayable && (
                  <BlockContainer key={index}>
                    <USPBackgroundImage {...block} isPreview={isPreview} />
                  </BlockContainer>
                )) ||
              (block.type === "monoOffer" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <MonoOffer {...block} isPreview={isPreview} />
                </BlockContainer>
              )) ||
              (block.type === "PricingBlock" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <PricingBlock {...block} />
                </BlockContainer>
              )) ||
              (block.type === "HowItWorksBlock" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <HowItWorksBlock {...block} />
                </BlockContainer>
              )) ||
              (block.type === "ReviewsBlock" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <ReviewsBlock {...block} />
                </BlockContainer>
              )) ||
              (block.type === "repairmenBlock" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <RepairmenBlock {...block} isPreview={isPreview} />
                </BlockContainer>
              )) ||
              (block.type === "LinksBlock" && block.isBlockDisplayable && (
                <BlockContainer key={index} noMargin>
                  <LinksBlock {...block} />
                </BlockContainer>
              )) ||
              (block.type === "conceptBlock" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <ConceptBlock {...block} isPreview={isPreview} />
                </BlockContainer>
              )) ||
              (block.type === "newHeaderBlock" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <NewHeaderBlock {...block} isPreview={isPreview} />
                </BlockContainer>
              )) ||
              (block.type === "optInBlock" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <OptInBlock {...block} isPreview={isPreview} />
                </BlockContainer>
              )) ||
              (block.type === "slider" && block.isBlockDisplayable && (
                <BlockContainer key={index}>
                  <SliderBlock {...block} isPreview={isPreview} />
                </BlockContainer>
              ))
          )}
        {!isPreview && <FooterBlock {...footerData} />}
      </div>
    </Layout>
  )
}

LandingPageTemplate.propTypes = {
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  contactText: PropTypes.string,
  headerBannerText: PropTypes.string,
  displayHeaderBanner: PropTypes.bool,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      background_image: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  headerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  footerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  products: PropTypes.array,
}

const LandingPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription,
    headerBannerText,
    displayHeaderBanner,
    blocks,
  } = data.landingPageData.frontmatter
  const navigation = {
    links: data.headerData.frontmatter.links,
    color: data.headerData.frontmatter.backgroundColor,
    subItemBlock: data.headerData.frontmatter.subItemBlock,
    enabledPhoneRenderer: data.headerData.frontmatter.enabledPhoneRenderer,
  }
  const footerData = data.footerData.frontmatter
  const products = data.productData.nodes

  return (
    <LandingPageTemplate
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      headerBannerText={headerBannerText}
      displayHeaderBanner={displayHeaderBanner}
      blocks={blocks}
      navigation={navigation}
      footerData={footerData}
      products={products}
    />
  )
}

export default LandingPage

export const pageQuery = graphql`
  query($id: String!, $header: String!) {
    landingPageData: markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoTitle
        seoDescription
        slug
        displayHeaderBanner
        headerBannerText
        header
        blocks {
          background_image
          USPType
          title
          searchTitle
          subtitle
          type
          workContent {
            image
            title
            text
            link
            url
          }
          variant
          firstTextBefore
          firstTextAfter
          firstTextAnimated
          firstTextAnimation
          secondTextBefore
          secondTextAfter
          secondTextAnimated
          secondTextAnimation
          thirdTextBefore
          thirdTextAfter
          thirdTextAnimated
          thirdTextAnimation
          content
          number
          isBlockDisplayable
          listingTitle
          listingSubtitle
          text
          emailPlaceholder
          buttonText
          isImageFirst
          image
          firstText
          secondText
          buttonLink
          displayBackground
          hasCustomColor
          customColor
          backgroundColor
          titleColor
          textColor
          isAdressInput
          textBackgroundColor
          isBackgroundWhite
          repairmenCarouselSubTitle
          trustTag
          blocColor
          secondBackgroundColor
          pressArticles {
            pressAltPicture
            pressLink
            pressPicture
            siteName
          }
          products {
            productAltPicture
            productPicture
            productName
            productLink
          }
          DIYButton
          DIYSubtitle
          DIYTitle
          ReconditionButton
          ReconditionSubtitle
          ReconditionTitle
          RepairButton
          RepairSubtitle
          RepairTitle
          RepairTooltipContent
          DIYContent {
            row
          }
          RepairContent {
            row
          }
          ReconditionContent {
            row
          }
          links {
            title
            url
          }
          monoOfferImage
          monoOfferImageAlt
          monoOfferRepairButton
          monoOfferRepairSubtitle
          monoOfferRepairTitle
          monoOfferRepairTooltipContent
          monoOfferRepairContent {
            row
          }
          newHeaderImage
          newHeaderImageAlt
          newHeaderTitle
          href
          newHeaderSubTitle
          newHeaderTextContent
          newHeaderTextBottom
          optInImage
          optInImageAlt
          optInTitle
          optInButton
          mobileImage
          optInText
          optInTextRgpd
          slides {
            title
            description
            picto
            link
            linkText
          }
          machinaSlides {
            title
            text
            backgroundColor
            textColor
            backgroundImage
            href
          }
          videoSlides {
            text
            backgroundImage
            href
            icon
          }
        }
      }
    }
    headerData: markdownRemark(frontmatter: { headerName: { eq: $header } }) {
      frontmatter {
        headerName
        enabledPhoneRenderer
        backgroundColor
        links {
          link {
            title
            url
            openLinkInNewTab
            menuItem {
              icon
              link
              title
              subTitle
            }
            subItemBlock {
              backgroundColor
              title
              subTitle
              url
              link
            }
          }
        }
      }
    }
    footerData: markdownRemark(
      frontmatter: { footerName: { eq: "Default footer" } }
    ) {
      frontmatter {
        footerName
        offerTitle
        offerLinks {
          offerLink {
            title
            url
            openLinkInNewTab
          }
        }
        commitmentsTitle
        commitmentsLinks {
          commitmentsLink {
            title
            url
            openLinkInNewTab
          }
        }
        cgv {
          title
          url
          openLinkInNewTab
        }
        legals {
          title
          url
          openLinkInNewTab
        }
        blog {
          title
          url
          openLinkInNewTab
        }
      }
    }
    productData: allProduct {
      nodes {
        name
        _id
        image
        slug
      }
    }
  }
`
